
const slideUno = {
  titulo: "EN SUCURSAL",
  img: "pago_4",
  desc: "Podés realizarlo en efectivo, con débito o cheque",
  desc2: "Bv. Las Heras Nª957"
}
const slideDos ={
  titulo: "TRANSFERENCIA",
  img: "pago_2",
  desc: "Para poder hacer efectivo el pago es obligatorio adjuntar el comprobante junto con el nombre del titular del servicio a gestion@scdplanet.com.ar",
  desc2: "Ver datos"
} 

const slideTres = {
  titulo: "DÉBITO AUTOMÁTICO",
  img: "pago_3",
  desc: "Podés realizarlo por cuenta bancaria o tarjeta VISA (de cualquier banco)",
  desc2: "Consulte"
} 

const slideCuatro = {
  titulo: "PAGO VIRTUAL",
  img: "pago_1",
  desc: "Utilizá el código para pago electrónico que se encuentra en tu factura.",
  desc2: "Ver ejemplo"
}  





export const slidesPagos = [slideUno, slideDos, slideTres, slideCuatro]