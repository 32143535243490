import React from "react";
import { Link } from "react-router-dom";

export const Cards = () => {
  // Arreglar con cards grid
  return (

<section id="cards" >
    <div className="card-group cards justify-content-center animate__animated animate__fadeIn mb-5 mx-5">
    <div className="col-12 col-md-5 col-lg-3 my-4">
        <div className="card rounded-0 shadow animate__animated animate__fadeInUp animate__fast">
          <div className="d-flex justify-content-between mx-2">
          <img
            src={`../assets/footageplanet/icon_fibraoptica.png`}
            style={{ width: "20%", height: "20%" }}
            className="mt-3 ml-3"
            alt="Logo fibra optica"
            />
             <img
            src={`../assets/footageplanet/ico_hogar.png`}
            style={{ width: "20%", height: "20%" }}
            className="mt-3 mr-3"
            alt="Logo fibra optica"
            />
            </div>
          <div className="card-body">
            <h4 className="card-title m-0 font-size-1">
            FIBRA ÓPTICA (154)
            </h4>
            <h4 className="card-title m-0 font-weight-bold"> HOGAR</h4>
            <br />
            <p className="card-text m-0">24 hs. Online</p>
            <p className="card-text m-0">Hasta 40 Mbps</p>
            
          </div>
          <Link to={{pathname: "/contacto", state: `Quiero solicitar mi plan HOGAR FIBRA ÓPTICA`  }}className={`btn-card btn-card-fibra`} >
            SOLICITAR
          </Link>
        </div>
      </div>
      <div className="col-12 col-md-5 col-lg-3 my-4">
        <div className="card rounded-0 shadow animate__animated animate__fadeInUp animate__fast">
          <div className="d-flex justify-content-between mx-2">
          <img
            src={`../assets/footageplanet/icon_fibraoptica.png`}
            style={{ width: "20%", height: "20%"}}
            className="mt-3 ml-3"
            alt="Logo fibra optica"
            />
             <img
            src={`../assets/footageplanet/ico_empresa.png`}
            style={{ width: "20%", height: "20%"}}
            className="mt-3 mr-3"
            alt="Logo fibra optica"
            />
            </div>
          <div className="card-body">
            <h4 className="card-title m-0 font-size-1">
            FIBRA ÓPTICA (155)
            </h4>
            <h4 className="card-title m-0 font-weight-bold"> EMPRESA</h4>
            <br />
            <p className="card-text m-0">24 hs. Online</p>
            <p className="card-text m-0">Hasta 70 Mbps</p>
           
          </div>
          <Link to={{pathname: "/contacto", state: `Quiero solicitar mi plan EMPRESA FIBRA ÓPTICA`  }}className={`btn-card btn-card-fibra`} >
            SOLICITAR
          </Link>
        </div>
      </div>
      <div className="col-12 col-md-5 col-lg-3 my-4">
        <div className="card rounded-0 shadow animate__animated animate__fadeInUp animate__fast">
          <div className="d-flex justify-content-between mx-2">
          <img
            src={`../assets/footageplanet/icon_inalambrico.png`}
            style={{width: "20%", height: "20%"}}
            className="mt-3 ml-3"
            alt="Logo fibra optica"
            />
             <img
            src={`../assets/footageplanet/ico_rural.png`}
            style={{ width: "20%", height: "20%"}}
            className="mt-3 mr-3"
            alt="Logo fibra optica"
            />
            </div>
          <div className="card-body">
            <h4 className="card-title m-0 font-size-1">
            INALÁMBRICO (150R)
            </h4>
            <h4 className="card-title m-0 font-weight-bold">RURAL</h4>
            <br />
            <p className="card-text m-0">24 hs. Online</p>
            <p className="card-text m-0">Hasta 10 Mbps</p>
           
          </div>
          <Link to={{pathname: "/contacto", state: `Quiero solicitar mi plan HOGAR INALAMBRICO`  }}className={`btn-card btn-card-inalambrico`} >
            SOLICITAR
          </Link>
        </div>
      </div>
      <div className="col-12 col-md-5 col-lg-3 my-4">
        <div className="card rounded-0 shadow animate__animated animate__fadeInUp animate__fast">
          <div className="d-flex justify-content-between mx-2">
          <img
            src={`../assets/footageplanet/icon_fibraoptica.png`}
            style={{ width: "20%", height: "20%" }}
            className="mt-3 ml-3"
            alt="Logo fibra optica"
            />
             <img
            src={`../assets/footageplanet/ico_socio.png`}
            style={{ width: "20%", height: "20%"}}
            className="mt-3 mr-3"
            alt="Logo fibra optica"
            />
            </div>
          <div className="card-body">
            <h4 className="card-title m-0 font-size-1">
            FIBRA ÓPTICA ()
            </h4>
            <h4 className="card-title m-0 font-weight-bold"> SOCIOS</h4>
            <br />
            <p className="card-text m-0">24 hs. Online</p>
            <p className="card-text m-0">Hasta 20 Mbps</p>
            
          </div>
          <Link to={{pathname: "/contacto", state: `Quiero solicitar mi plan FIBRA ÓPTICA SOCIOS`  }}className={`btn-card btn-card-fibra`} >
            SOLICITAR
          </Link>
        </div>
      </div>
      

</div>
<div className="mb-5 mt-2 d-flex justify-content-center">
<Link to="/planes" className="text-dark" >
<p className="font-weight-bold pl-3" >VER TODOS LOS PLANES</p>

</Link>

{/* 
    <a href="/planes" className="text-dark">VER TODOS LOS PLANES</a> */}

</div>

</section>

    
  );
};