import React from "react";
import { consumosInfo } from "./SlidesConsumos";


export const ConsumoRec = () => {
  return (
    <>

      <table className="consumoTabla p-md-2 mt-2 ">

        <thead className="thead-consumo ">
          <tr className="">
            
            <th className="p-2 text-center">VELOCIDAD<br />DE BAJADA</th>
            <th className="p-2 text-center">PLATAFORMA</th>
            <th className="p-2 text-center">CALIDAD</th>
          </tr>
        </thead>
        <tbody>
          {consumosInfo.map(((tr, index)=>{
            return (
    
          <tr className={`p-2 ${index%2!==0 ? "bg-gris" : ""}`}>
            <td className="p-1 td-consumo ">{tr.velocidad}</td>
            <td className="p-1 td-img text-center">
            <img
              src={`../assets/footageplanet/${tr.img}.png`}
              alt={tr.img}
               className=""
              />
              </td>
            <td className="p-1 td-consumo  ">{tr.calidad}</td>
          </tr>
      

            )
          }))}

        </tbody>
      </table>
      {/* </Table> */}
    </>
  );
};
