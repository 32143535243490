import React from "react";

const slideUno = (
  <div className="optim__text font-bold mb-2 ">
    <div className="">
      <div className="row  justify-content-center align-items-center">
        <div className="d-block d-md-flex justify-content-start">
          <img
            className=""
            src={"../assets/footageplanet/recomendaciones_1.png"}
            alt="Consejo de optimizacion 1"
          />
        </div>
      </div>
      <h1 className="m-0 font-weight-bold">01</h1>
      <div className="row justify-content-center align-items-center">
        <div className="col-10">
          <p className="mt-3 w-100 ">
            Cabléa todos los dispositivos que puedan conectarse a través del
            puerto ethernet vía cable utp (consolas de video juegos, tv, pc de
            escritorio, etc.)
          </p>
          <p className="mb-4 d-none d-md-block font-weight-bold">
            Vas a lograr una conexión más segura y estable.
          </p>
        </div>
      </div>
    </div>
  </div>
);

const slideDos = (
  <div className="optim__text font-bold mb-2 ">
    <div className="">
      <div className="row justify-content-center align-items-center">
        <div className="d-block d-md-flex justify-content-start">
          <img
            className=""
            src={"../assets/footageplanet/recomendaciones_2.png"}
            alt="Consejo de optimizacion 1"
          />
        </div>
      </div>
      <h1 className="m-0 font-weight-bold">02</h1>
      <div className="row justify-content-center align-items-center">
        <div className="col-10">
          <p className="mt-3 w-100 ">
            En los ambientes en los cuales no tengas señal, podes colocar un
            router, previamente cableado a la onu.
          </p>
        </div>
      </div>
    </div>
  </div>
);

const slideTres = (
  <div className="optim__text font-bold mb-2 ">
    <div className="">
      <div className="row justify-content-center align-items-center">
        <div className="d-block d-md-flex justify-content-start">
          <img
            className=""
            src={"../assets/footageplanet/recomendaciones_3.png"}
            alt="Consejo de optimizacion 1"
          />
        </div>
      </div>
      <h1 className="m-0 font-weight-bold">03</h1>
      <div className="row justify-content-center align-items-center">
        <div className="col-10">
          <p className="mt-3 w-100 ">
            Te recomendamos usar un router dual band cuando tus dispositivos lo
            soportan, ya que estos te permitirán acceder a la mayor velocidad.
          </p>
        </div>
      </div>
    </div>
  </div>
);

const slideCuatro = (
  <div className="optim__text font-bold mb-2 ">
    <div className="">
      <div className="row justify-content-center align-items-center">
        <div className="d-block d-md-flex justify-content-start">
          <img
            className=""
            src={"../assets/footageplanet/recomendaciones_4.png"}
            alt="Consejo de optimizacion 1"
          />
        </div>
      </div>
      <h1 className="m-0 font-weight-bold">04</h1>
      <div className="row justify-content-center align-items-center">
        <div className="col-10">
          <p className="mt-3 w-100 ">
            Evita colocar el router detrás de paredes o cerca de microondas y
            otros dispositivos electrónicos.
          </p>
        </div>
      </div>
    </div>
  </div>
);

const slideCinco = (
  <div className="optim__text font-bold mb-2 ">
    <div className="">
      <div className="row justify-content-center align-items-center">
        <div className="d-block d-md-flex justify-content-start">
          <img
            className=""
            src={"../assets/footageplanet/recomendaciones_5.png"}
            alt="Consejo de optimizacion 1"
          />
        </div>
      </div>
      <h1 className="m-0 font-weight-bold">05</h1>
      <div className="row justify-content-center align-items-center">
        <div className="col-10">
          <p className="mt-3 w-100">
            Recordá que mientras más desactualizado esté tu dispositivo, menos
            megas podrá medir.
          </p>
        </div>
      </div>
    </div>
  </div>
);
export const slidesOptim = [
  slideUno,
  slideDos,
  slideTres,
  slideCuatro,
  slideCinco,
];
