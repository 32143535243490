import React from "react";

export const Footer = () => {
  return (
    <footer className="" id="contacto">
      <div className="bg-black padding-top-100 padding-bottom-100">
        <div className="container-fluid">
          <div className="row justify-content-md-center">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 text-center-m padding-bottom-50-m">
              <img
                src={`../assets/footageplanet/logo_blanco.png`}
                alt=""
                style={{ height: "60%", maxheight: "50px"}}
                className=""
              />
              <p className="color-white font-size-11 font-300 padding-left-10">
                © 2021 SCD Planet S.A.
              </p>
            </div>
            <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3 border-rigth-white  padding-bottom-50-m d-flex justify-content-center justify-content-lg-start align-items-center">
             <div className="d-flex flex-column">

             
              <p className="color-white font-size-17 font-300 padding-left-10">
                <span className="font-700">02223 44-2115</span>
                <br />
              </p>
              <div className="padding-left-10 text-center">
                <a className="pr-1 " href="http://www.facebook.com/scdplanet">
                  <img
                    src={`../assets/footageplanet/rrss_facebook.png`}
                    alt="logo facebook"
                    className="img-fluid"
                    style={{ height: "25px" }}
                  />
                </a>
                <a className="pr-1 " href="http://www.instagram.com/scdplanet">
                  <img
                    src={`../assets/footageplanet/rrss_instagram.png`}
                    alt="logo instagram"
                    className="img-fluid"
                    style={{ height: "25px" }}
                  />
                </a>
              </div>
              </div>
            </div>

            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 pl-0-m padding-left-50 text-center-m">
              <p className="color-white font-size-17 font-300 padding-left-10">
              Bv. las Heras Nº 957 
                <br />
                Coronel Brandsen 
                <br />
                Provincia de Buenos Aires
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
