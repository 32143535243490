import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";

export const NavBarPlanet = () => {

  let toggleNavBar = (e)=>{
    let navbar = document.getElementById('navbar');
    console.log("type: ", e.target.value)
    navbar.classList.toggle("activeMobile")
  }
  return (
    <Navbar id="navbar" sticky="top" expand="lg" variant="dark" className="navbar-black " >
      <div className=" d-md-flex  mx-md-auto ">
    <div className="d-flex justify-content-between px-3"> 

          <Link className="mr-5" to="/">
            <img
              src={`../assets/footageplanet/logo_blanco.png`}
              alt="Planet logo"
              className="pt-4 logo-navbar"
              // style={{ height: "85%" }}
              />
          </Link>
        <Navbar.Toggle className=" " value= "1" onClick={(e)=>toggleNavBar(e)} aria-controls="basic-navbar-nav" />
              </div>

        <Navbar.Collapse id="basic-navbar-nav" >
          <Nav className="ml-2">
            <div className="navbar-collapse d-flex ml-150">
              <div className="d-md-flex  align-items-bottom">
                <div className="navbar-nav mt-1">
                  <NavLink
                    activeClassName="active"
                    className="nav-item nav-link text-white text-menu"
                    exact
                    to="/planes"
                  >
                    PLANES
                  </NavLink>
                  <NavLink
                    activeClassName="active"
                    className="nav-item nav-link text-white text-menu"
                    exact
                    to="/zonasdecobertura"
                  >
                    ZONA DE COBERTURA
                  </NavLink>
                  <NavLink
                    activeClassName="active"
                    className="nav-item nav-link text-white text-menu"
                    exact
                    to="/recomendaciones"
                  >
                    RECOMENDACIONES
                  </NavLink>
                 
                  <NavLink
                    activeClassName="active"
                    className="nav-item nav-link text-white text-menu "
                    exact
                    to="/nosotros"
                  >
                    NOSOTROS
                  </NavLink>
                  <NavLink
                    activeClassName="active"
                    className="nav-item nav-link text-white text-menu"
                    exact
                    to="/contacto"
                  >
                    CONTACTO
                  </NavLink>
                </div>

                <div className="navbar-nav ml-0 mb-3 ml-md-2 mb-md-0 d-flex flex-row">
                  <a
                    className="pr-1 pt-2 m-0"
                    href="http://www.facebook.com/scdplanet"
                  >
                    <img
                      src={`../assets/footageplanet/rrss_facebook.png`}
                      alt="logo facebook"
                      className="img-fluid"
                      style={{ height: "20px" }}
                    />
                  </a>
                  <a
                    className="pr-1 pt-2 m-0"
                    href="http://www.instagram.com/scdplanet"
                  >
                    <img
                      src={`../assets/footageplanet/rrss_instagram.png`}
                      alt="logo instagram"
                      className="img-fluid"
                      style={{ height: "20px" }}
                    />
                  </a>
                </div>
              </div>
            </div>
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
};
