import React from "react";
import { Link } from "react-router-dom";

export const Recomendaciones = () => {
  return (
   
<section id="recomendaciones"   className="mt-8 mb-8 d-block w-75">
    <div  className="row justify-content-md-center ">
        <div className="col-11 col-md-5 mt-1 mb-5 " >
            <h5 className="font-size-075 font-weight-bold font-montserrat">RECOMENDACIONES</h5>
            <hr className="w-75 ml-0" />
            <div className="d-flex flex-column ">
            <p className="font-weight-light font-size-36">Estamos </p>
            <p className=" font-weight-light font-size-36">para ayudarte</p>
            </div>
        </div>
        <div className="col-10 col-md-5 d-flex flex-column mt-1 mt-md-5 ml-md-4 ">
        
        <Link
              className="d-flex flex-row recomendacionesBoton align-items-center mb-4"
              to={{pathname: "/recomendaciones", state: {optimizar: true, consumo: false, facturacion: false }}}
              >
              <img
                src={`../assets/footageplanet/flecha.png`}
                alt="Planet logo"
                className="img-fluid"
                style={{height: '20px'}}
                />
                <p className="font-weight-light font-size-28 font-montserrat pl-3" >Optimizar la conexión</p>
            </Link>
        <Link
              
              className="d-flex flex-row recomendacionesBoton align-items-center mb-4"
            
              to={{pathname: "/recomendaciones", state: {optimizar: false, consumo: true, facturacion: false }}}

              >
              <img
                src={`../assets/footageplanet/flecha.png`}
                alt="Planet logo"
                className="img-fluid"
                style={{height: '20px'}}
                />
                <p className="font-weight-light font-size-28  font-montserrat pl-3" >Consumo de ancho de banda</p>
            </Link>
        <Link
             
              className="d-flex flex-row recomendacionesBoton align-items-center mb-4"
              to={{pathname: "/recomendaciones", state: {optimizar: false, consumo: false, facturacion: true }}}

              >
              <img
                src={`../assets/footageplanet/flecha.png`}
                alt="Planet logo"
                className="img-fluid"
                style={{height: '20px'}}
                />
                <p className="font-weight-light font-size-28  font-montserrat pl-3" >Cómo pagar mi factura</p>
            </Link>
                
        </div>
    </div>

</section>


    
  );
};
