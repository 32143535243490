import React, { useEffect, useState }  from "react";

//import { SelectCiudad } from "./SelectCiudad";
import { dataZonas, accordionData } from "./dataZonas";
import { Accordion } from "./Accordion";

export const ZonasScreen = () => {
  const [zonaSel, setZonaSel] = useState("BRANDSEN");
  const [data, setData] = useState({ ciudad: "" , zona: "", tipo: "", icon: "", content: "", mapa: "", open: ""})
  
  useEffect(() => {

    accordionData.map(
      ({ ciudad, zona, tipo, icon, content, mapa, open }) =>
        ciudad === zonaSel ? (
          
         setData({ciudad, zona, tipo, icon, content, mapa, open})
        ) : (
          ""
        )
    )
  }, [zonaSel])
  
  console.log(data, "mapa");
  let handleChange = (e) => {
    setZonaSel(e.target.value);
  };
  const options = [];
  dataZonas.map((z) => options.push({ zona: z, label: z }));

  //console.log(zonaSel, data);
  return (
    <>
      <div className="row  mr-0 zona-height">
        <div className="col-12 col-md-4 mt-md-5 ">
          <div className=" m-4 pl-md-5">
            <p className="font-weight-light mb-0 pb-2 ">ELEGÍ TU ZONA</p>
            <div>
              <select
                className="text-dark custom-select custom-select-lg border-0  font-weight-bold pl-2 py-0"
                value={zonaSel}
                onChange={handleChange}
              >
                {dataZonas.map((z) => (
                  <option
                    className="ciudad-option"
                    value={z}
                    key={z}
                    styles={{ padding: "50px 10px 20px 30px" }}
                  >
                    {z}{" "}
                  </option>
                ))}
              </select>
              <hr className="m-2" />

              <div className="accordion  mt-2">
                {accordionData.map(
                  ({ ciudad, zona, tipo, icon, content, mapa, open }) =>
                    ciudad === zonaSel ? (
                      
                      <Accordion
                        key={`${zona}${tipo}`}
                        zona={zona}
                        tipo={tipo}
                        icon={icon}
                        content={content}
                        mapa={mapa}
                        open={open}
                      />
                    ) : (
                      ""
                    )
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 col-sm-8 m-0 p-0 mapStyle">
          <div className="mapContainer">
            <iframe
              title="Mapa zona A"
              scrolling="no"
              src={data.mapa}
              frameBorder="0"
              width="100%"
              height="100%"
              style={{ border: "0", marginTop: "-60px", float: "right" }}
              marginHeight="0"
              marginWidth="0"
              loading="lazy"
            ></iframe>
          </div>
        </div>
      </div>
    </>
  );
};
