import React from 'react';
import ReactDOM from 'react-dom';
import { PlanetWeb } from './PlanetWeb';


ReactDOM.render(

  <PlanetWeb />,
  document.getElementById('root')

);
