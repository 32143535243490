import React from "react";
import { Footer } from "../ui/Footer";
import { Contacto } from "../ui/Contacto";

export const NosotrosScreen = () => {
  return (
    <>
      <section id="nosotros" className="m-5 animate__animated animate__fadeIn">
        <h3 className="font-weight-bold text-center p-5">NOSOTROS</h3>
        <p className="text-center py-3 font-size-15">
          <span className="font-weight-bold"> SCDPLANET S.A.</span>, comienza sus actividades en la ciudad de Brandsen, el
          día 15 de diciembre de 1998, <br/> con el motivo de cubrir una necesidad
          insatisfecha hasta ese momento:<span className="font-weight-bold"> Servicios de Internet<span/></span>.
        </p>

        <p className="text-center py-3 font-size-15">
          Desde entonces, nos encontramos trabajando en forma constante,
          ofreciendo un amplio abanico<br/> de actividades, llevadas a cabo por gente
          joven, con la experiencia y conocimientos suficientes para<br/> brindarle a
          nuestros clientes... <span className="font-weight-bold">Simplemente soluciones.</span>
        </p>
      </section>

      <section id="zona-cobertura" className="mt-8">
        <div className="row m-0" style={{ height: "450px" }}>
          <div className="col-12 col-md-6 p-0 nosotros-a"></div>
          <div className="col-12 col-md-6 p-0 nosotros-b d-md-flex justify-content-center align-items-center">
            <img
              className="img-fluid"
              style={{ width: "40%" }}
              src={
                process.env.PUBLIC_URL +
                "/assets/footageplanet/logo_planet_nosotros.png"
              }
              alt="zonas de cobertura"
            />
          </div>
        </div>
      </section>

      <section
        id="empresa"
        className="bg-grisv pt-8 px-4 px-md-5 pb-4 pb-md-5"
        style={{ height: "450px" }}
      >
        <p className="font-weight-bold text-center text-white p-0 m-0 font-size-26">
          Una empresa inquieta, que siempre
        </p>
        <p className="font-weight-bold text-center text-white p-0 m-0 font-size-26">
          busca nuevas tecnologías para poder
        </p>
        <p className="font-weight-bold text-center text-white p-0 m-0 font-size-26">
          comunicar de la mejor manera.
        </p>
        <div className="d-none d-md-flex justify-content-center my-4 ">
          <img
            className="img-fluid"
            style={{ height: "50px" }}
            src={
              process.env.PUBLIC_URL + "/assets/footageplanet/line_vertical.png"
            }
            alt="zonas de cobertura"
          />
        </div>
        <p className="text-center text-white pt-4 p-md-0 m-0 font-size-15">
          Primero fue internet telefónico, luego internet inalámbrico
        </p>
        <p className="font-weight-bold text-center text-white p-0 m-0 font-size-15">
          y actualmente internet por FIBRA ÓPTICA.
        </p>
      </section>
      <Contacto />
      <Footer />
    </>
  );
};
