import React, { useEffect, useState } from "react";
import { Hero } from "./Hero";
import { Cards } from "./Cards";
import { ZonaCobertura } from "../ui/ZonaCobertura";
import { Recomendaciones } from "../ui/Recomendaciones";
import { Footer } from "../ui/Footer";
import { Contacto } from "../ui/Contacto";
//import { CarouselPlanet } from "./CarouselPlanet";

export const HomeScreen = () => {
 
const [scroll, setScroll] = useState(0)
  
window.addEventListener("scroll", (e) => {
  setScroll(window.scrollY)
});
  useEffect(() => {
    let navbar = document.getElementById('navbar');
    if(scroll <=150){
      // console.log(scroll);
      navbar.classList.add('activeBar');
    } else {
      // console.log("Menor que 100")
      navbar.classList.remove("activeBar");

    }

  }, [scroll])
 

  return (
    <>
      {/* <CarouselPlanet /> */}
      <Hero/>
      <Cards />
      <ZonaCobertura />
      <Recomendaciones />
      <Contacto />
      <Footer />
    </>
  );
};
