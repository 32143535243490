import React from "react";
import { CardPlan } from "./CardPlan";

export const CardList = ({tipo}) => {
  //console.log(tipo);
  const planUno = {
    id: 154, 
    tipo: "FIBRA ÓPTICA", 
    icon: "icon_fibraoptica",
    icon_tipo: "hogar",
    titulo: "HOGAR",
    velocidad: "40",
    descUno: "6 Casillas e-mail",
    descDos: "Hasta 40 Mbps",
    precio: " 3500.-",
    btnClass: "fibra",
  };
  const planDos = {
    id: 224,  
    tipo: "FIBRA ÓPTICA", 
    icon: "icon_fibraoptica",
    icon_tipo: "hogar",
    titulo: "HOGAR PLUS",
    velocidad: "50",
    descUno: "6 Casillas e-mail",
    descDos: "Hasta 50 Mbps",
    precio: " 2500.-",
    btnClass: "fibra",
  };
  const planTres = {
    id: 150,  
    tipo: "INALÁMBRICO",
    icon: "icon_inalambrico",
    icon_tipo: "hogar",
    titulo: "HOGAR", 
    velocidad: "10",
    descUno: "6 Casillas e-mail",
    descDos: "Hasta 10 Mbps",
    precio: " 2500.-",
    btnClass: "inalambrico",
  };
  const planCuatro = {
    id: 155,  
    tipo: "FIBRA ÓPTICA", 
    icon: "icon_fibraoptica",
    icon_tipo: "empresa",
    titulo: "EMPRESA",
    velocidad: "70",
    descUno: "6 Casillas e-mail",
    descDos: "Hasta 70 Mbps",
    precio: " 3500.-",
    btnClass: "fibra",
  };
  const planCinco = {
    id: 259,  
    tipo: "FIBRA ÓPTICA", 
    icon: "icon_fibraoptica",
    icon_tipo: "empresa",
    titulo: "EMPRESA PLUS",
    velocidad: "85",
    descUno: "6 Casillas e-mail",
    descDos: "Hasta 85 Mbps",
    precio: " 2500.-",
    btnClass: "fibra",
  };
  const planSeis = {
    id: 225,  
    tipo: "INALÁMBRICO",
    icon: "icon_inalambrico",
    icon_tipo: "hogar",
    titulo: "HOGAR PLUS", 
    velocidad: "13",
    descUno: "6 Casillas e-mail",
    descDos: "Hasta 13 Mbps",
    precio: " 2500.-",
    btnClass: "inalambrico",
  };
  const planSiete = {
    id: 5,  
    tipo: "FIBRA ÓPTICA", 
    icon: "icon_fibraoptica",
    icon_tipo: "premium",
    titulo: "PREMIUM",
    velocidad: "100",
    descUno: "6 Casillas e-mail",
    descDos: "Hasta 100 Mbps",
    precio: " 2500.-",
    btnClass: "fibra",
  };
  const planOcho = {
    id: 151,  
    tipo:"INALÁMBRICO", 
    icon: "icon_inalambrico",
    icon_tipo: "empresa",
    titulo: "EMPRESA",
    velocidad: "15",
    descUno: "6 Casillas e-mail",
    descDos: "Hasta 15 Mbps",
    precio: " 3500.-",
    btnClass: "inalambrico",
  };
  const planNueve = {
    id: 4248,  
    tipo:"INALÁMBRICO", 
    icon: "icon_inalambrico",
    icon_tipo: "empresa",
    titulo: "EMPRESA PLUS",
    velocidad: "20",
    descUno: "6 Casillas e-mail",
    descDos: "Hasta 20 Mbps",
    precio: " 2500.-",
    btnClass: "inalambrico",
  };
  const planDiez = {
    id: 19,  
    tipo:"INALÁMBRICO", 
    icon: "icon_inalambrico",
    icon_tipo: "premium",
    titulo: "PREMIUM",
    velocidad: "23",
    descUno: "6 Casillas e-mail",
    descDos: "Hasta 23 Mbps",
    precio: " 2500.-",
    btnClass: "inalambrico",
  };
  const planOnce = {
    id: "150R",  
    tipo:"INALÁMBRICO", 
    icon: "icon_inalambrico",
    icon_tipo: "rural",
    titulo: "RURAL",
    velocidad: "10",
    descUno: "6 Casillas e-mail",
    descDos: "Hasta 10 Mbps",
    precio: " 2500.-",
    btnClass: "inalambrico",
  };
  const planDoce = {
    id: "",  
    tipo: "FIBRA ÓPTICA",
    icon: "icon_fibraoptica",
    icon_tipo: "socio",
    titulo: "SOCIO", 
    velocidad: "20",
    descUno: "6 Casillas e-mail",
    descDos: "Hasta 20 Mbps",
    precio: " 2500.-",
    btnClass: "fibra",
  };
  const cards = [planUno, planDos, planTres, planCuatro, planCinco, planSeis, planSiete, planOcho, planNueve, planDiez, planOnce, planDoce];


  return (
    <div className="card-group animate__animated animate__fadeIn mb-5 mx-5">
      {
      cards.map((card) => (
         card.btnClass === tipo?  <CardPlan key={card.id} {...card} /> : ""
                
      ))}
    </div>
  );
};

