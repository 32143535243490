import React, { useRef, useState } from "react";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { useHistory, useLocation } from "react-router-dom";

export const Contacto = ()=> {
    
    const recaptcha = useRef(null);
    const mensajeContacto = useLocation();
    let history = useHistory();
    const [okSubmit, setOkSubmit] = useState(null);
    const [captchaValido, setCaptchaValido] = useState(null);
  
  
    /* NEW: Input state handling vvvv */
  const [inputs, setInputs] = useState({
    email: "",
    nombre: "",
    celular: "",
    motivo: "Ventas",
    comentario: mensajeContacto.state || "",
  });
  const onChangeCaptcha = (value) => {
    if (recaptcha.current.getValue()) {
      //console.log("no es robot");
      setCaptchaValido(true);
    }
  };

  const handleOnChange = event => {
    event.persist();
    setInputs(prev => ({
      ...prev,
      [event.target.id]: event.target.value
    }));
  };
  /* End input state handling ^^^^ */

  // Server state handling
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null
  });
  const handleServerResponse = (ok, msg) => {
    setServerState({
      submitting: false,
      status: { ok, msg }
    });
    if (ok) {
      setInputs({
        email: "",
        nombre: "",
        celular: "",
        comentario: mensajeContacto.state || "",
      });
      console.log(serverState)
    }
  };
  const handleOnSubmit = event => {
    event.preventDefault();
    if (recaptcha.current.getValue()) {
    setServerState({ submitting: true });
    axios({
      method: "POST",
      url: "https://formspree.io/f/xleaaove",
      data: inputs
    })
      .then(r => {
        setOkSubmit(<div class="position-fixed bottom-0 right-0 p-3" style={{zIndex: 999999, right: 0, bottom: 10}}>
        <div className="btn btn-success">Mensaje enviado</div>
        </div>)

    handleServerResponse(true, "Thanks!");
      })
      .catch(r => {
        setOkSubmit(<div class="position-fixed bottom-0 right-0 p-3" style={{zIndex: 999999, right: 0, bottom: 10}}>
        <div className="btn btn-error">Mensaje no enviado</div>
        </div>)
        handleServerResponse(false, r.response.data.error);
      });
    } else {
        console.log("Por favor acepta el captcha");
        setCaptchaValido(false);
      }

  };

  return (
    <section id="contacto" className="">
    <div className="padding-top-100 padding-bottom-100 bg-formulario-ligth">
      <div className="container-fluid">
        <div className="row">
          <div className="text-center col-xs-12 col-sm-12 padding-bottom-50">
            <h3 className="">
              <b>CONTACTO</b>
            </h3>
          </div>
          <form action="" onSubmit={handleOnSubmit} method="post">
            <div className="col-xs-12 col-sm-6">
              <label htmlFor="">Nombre y apellido</label>
              <input
                required
                type="text"
                name="nombre"
                id="nombre"
                onChange={handleOnChange}
                value={inputs.nombre}
              />

              <label htmlFor="">Celular</label>
              <input
                type="number"
                name="celular"
                id="celular"
                onChange={handleOnChange}
                value={inputs.celular}
              />
              <label htmlFor="">E-mail</label>
              <input
               id="email"
                     type="email"
                     name="email"
                     className="input-contact"
                     required
                     onChange={handleOnChange}
                     value={inputs.email}
              />
          
            </div>
            <div className="col-xs-12 col-sm-6" id="formContacto">
              {/* Form con Select */}
              <label htmlFor="">Motivo de consulta</label>
              <select
                name="motivo"
                id="motivo"
                className="input-contact font-weight-light"
                onChange={handleOnChange}
                     value={inputs.motivo}
              >
                {mensajeContacto.state !== null ? (
                  <option value="Ventas">Ventas</option>
                ) : (
                  <option value="Ventas">Ventas</option>
                )}
                <option value="Administración">Administración</option>
                <option value="Ventas">Ventas</option>
                <option value="Instalación">Instalación</option>
                <option value="Otra consulta">Otra consulta</option>
              </select>
              <label htmlFor="" id="mensajeContacto">
                Mensaje
              </label>
              {mensajeContacto.state !== null ? (
                <textarea
                  name="comentario"
                  id="comentario"
                  cols="30"
                  rows="10"
                  className="input-contact"
                  value={mensajeContacto.state || ""}
                  onChange={handleOnChange}
                ></textarea>
              ) : (
                <textarea
                  name="comentario"
                  id="comentario"
                  cols="30"
                  rows="10"
                  className="input-contact"
                  onChange={handleOnChange}
                ></textarea>
              )}

              <input type="hidden" name="send" id="email-send" />
              <ReCAPTCHA
                ref={recaptcha}
                sitekey="6LeW3sMbAAAAABUlrsZt3K7xsaAs6rIP-0LzCYw4"
                onChange={onChangeCaptcha}
              />
            </div>
            {captchaValido === false && (
              <div className="btn btn-danger">Por favor acepta el catcha</div>
            )}
            <div className="success-login"></div>
            <div className="text-center">
              <input
                type="submit"
                value="Enviar"
                name="submitForm"
                className="button-form"
                />
            </div>
                {okSubmit}
          </form>
        </div>
        <div className="clearfix"></div>
      </div>
    </div>
  </section>
  );
};
