
export const dataZonas = ["BRANDSEN", "JEPPENER", "BELGRANO", "RANCHOS", "VILLANUEVA", "ALTAMIRANO", "LOMA VERDE"]

export const accordionData = [
  {ciudad: "BRANDSEN",
    zona: "ZONA FIBRA",
    tipo: "FIBRA ÓPTICA",
    icon: "icon_fibraoptica",
    mapa: "https://www.google.com/maps/d/embed?mid=1nSghI450o1BVRQdVlTf69M_8cjKhhNYB&ll=-35.1609338463335%2C-58.23567254836267&z=13",
    content: [
      "Casco Centrico",
      "Las mandarinas",
      "Barrio parque",
      "La manzana",
      "La dolly 1 y 2",
      "Republica",
      "Matadero",
      "Infanta Isabel",
      "Las higueras",
      "Las americas",
      "Los pinos",
      "Los tilos",
      "La cañada",
      "Los aromos",
      "Los Naranjos",
      "Barrio Terranova",
      "Barrio Malvinas 2"
    ],
    open: false,
  },
  {ciudad: "BRANDSEN",
    zona: "ZONA A",
    tipo: "INALÁMBRICO",
    icon: "icon_inalambrico",
    mapa: "https://www.google.com/maps/d/embed?mid=1nSghI450o1BVRQdVlTf69M_8cjKhhNYB&ll=-35.1609338463335%2C-58.23567254836267&z=13",
    content: [
      "Barrio la Parada",
      "Las Acacias",
      "Barrio Mendizabal",
      "Barrio Malvinas 1",
      "Zona Ruta 54",
      "La Plantación",
    ],
    open: false 
  }, 
  {ciudad: "BRANDSEN",
  zona: "ZONA B",
  tipo: "INALÁMBRICO",
  icon: "icon_inalambrico",
  mapa: "https://www.google.com/maps/d/embed?mid=1nSghI450o1BVRQdVlTf69M_8cjKhhNYB&ll=-35.1609338463335%2C-58.23567254836267&z=13",
  content: [
"Zonas aledañas"
  ],
},
  {ciudad: "JEPPENER",
    zona: "ZONA A",
    tipo: "INALÁMBRICO",
    icon: "icon_inalambrico",
    mapa: "https://www.google.com/maps/d/embed?mid=1nSghI450o1BVRQdVlTf69M_8cjKhhNYB&ll=-35.27785937142856%2C-58.19690698571429&z=14",
    content: [
      "Casco centrico"
    ],
  },
  {ciudad: "JEPPENER",
    zona: "ZONA B",
    tipo: "INALÁMBRICO",
    icon: "icon_inalambrico",
    mapa: "https://www.google.com/maps/d/embed?mid=1nSghI450o1BVRQdVlTf69M_8cjKhhNYB&ll=-35.27785937142856%2C-58.19690698571429&z=14",
    content: [
"Zonas aledañas"
    ],
  },
  {ciudad: "BELGRANO",
    zona: "ZONA A",
    tipo: "INALÁMBRICO",
    icon: "icon_inalambrico",
    mapa: "https://www.google.com/maps/d/embed?mid=1nSghI450o1BVRQdVlTf69M_8cjKhhNYB&ll=-35.756944127272746%2C-58.49623424545456&z=13",
    content: [
      "Casco centrico"
    ],
  },
  {ciudad: "BELGRANO",
    zona: "ZONA B",
    tipo: "INALÁMBRICO",
    icon: "icon_inalambrico",
    mapa: "https://www.google.com/maps/d/embed?mid=1nSghI450o1BVRQdVlTf69M_8cjKhhNYB&ll=-35.756944127272746%2C-58.49623424545456&z=13",
    content: [
"Zonas aledañas"
    ],
  },
  {ciudad: "RANCHOS",
    zona: "ZONA A",
    tipo: "INALÁMBRICO",
    icon: "icon_inalambrico",
    mapa: "https://www.google.com/maps/d/embed?mid=1nSghI450o1BVRQdVlTf69M_8cjKhhNYB&ll=-35.5140253818182%2C-58.32596812727271&z=14",
    content: [
      "Casco centrico"
    ],
  },
  {ciudad: "RANCHOS",
    zona: "ZONA B",
    tipo: "INALÁMBRICO",
    icon: "icon_inalambrico", 
    mapa: "https://www.google.com/maps/d/embed?mid=1nSghI450o1BVRQdVlTf69M_8cjKhhNYB&ll=-35.5140253818182%2C-58.32596812727271&z=14",
    content: [
"Zonas aledañas"
    ],
  },
  {ciudad: "VILLANUEVA",
    zona: "ZONA A",
    tipo: "INALÁMBRICO",
    icon: "icon_inalambrico",
    mapa: "https://www.google.com/maps/d/embed?mid=1Z9SyH3H1T9fWS9evnkTLF0QoAouH3Q1S&ll=-35.67592655288717%2C-58.43482981416686&z=14",
    content: [
      "Casco centrico"
    ],
  },
  {ciudad: "VILLANUEVA",
    zona: "ZONA B",
    tipo: "INALÁMBRICO",
    icon: "icon_inalambrico",
    mapa: "https://www.google.com/maps/d/embed?mid=1Z9SyH3H1T9fWS9evnkTLF0QoAouH3Q1S&ll=-35.67592655288717%2C-58.43482981416686&z=14",
    content: [
"Zonas aledañas"
    ],
  },
  {ciudad: "ALTAMIRANO",
    zona: "ZONA A",
    tipo: "INALÁMBRICO",
    icon: "icon_inalambrico", 
    mapa: "https://www.google.com/maps/d/embed?mid=1Z9SyH3H1T9fWS9evnkTLF0QoAouH3Q1S&ll=-35.36144090112325%2C-58.15146437291024&z=15",

    content: [
      "Casco centrico"
    ],
  },
  {ciudad: "ALTAMIRANO",
    zona: "ZONA B",
    tipo: "INALÁMBRICO",
    icon: "icon_inalambrico",
    mapa: "https://www.google.com/maps/d/embed?mid=1Z9SyH3H1T9fWS9evnkTLF0QoAouH3Q1S&ll=-35.36144090112325%2C-58.15146437291024&z=15",

    content: [
"Zonas aledañas"
    ],
  },{ciudad: "LOMA VERDE",
  zona: "ZONA A",
  tipo: "INALÁMBRICO",
  icon: "icon_inalambrico", 
  mapa: "https://www.google.com/maps/d/embed?mid=1Z9SyH3H1T9fWS9evnkTLF0QoAouH3Q1S&ll=-35.274003887212075%2C-58.40387178313786&z=15",

  content: [
    "Casco centrico"
  ],
},
{ciudad: "LOMA VERDE",
  zona: "ZONA B",
  tipo: "INALÁMBRICO",
  icon: "icon_inalambrico",
  mapa: "https://www.google.com/maps/d/embed?mid=1Z9SyH3H1T9fWS9evnkTLF0QoAouH3Q1S&ll=-35.274003887212075%2C-58.40387178313786&z=15",
  content: [
"Zonas aledañas"
  ],
},
 
];