import React from 'react'
// import React, { useContext } from 'react'
import {
    BrowserRouter as Router,
    Switch
  } from 'react-router-dom';

// import { AuthContext } from '../auth/AuthContext';
// import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';

// import { LoginScreen } from '../components/login/LoginScreen';
import { DashboardRoutes } from './DashboardRoutes';
import ScrollToTop from '../hooks/ScrollToTop'


export const AppRouter = () => {

    //const { user } = useContext(AuthContext);

    return (
        <Router>
             <ScrollToTop />
            <div>
                <Switch> 
               
                    <PublicRoute 
                        path="/" 
                        component={ DashboardRoutes } 
                        isAuthenticated={ false }
                    />
                    {/* <PublicRoute 
                        exact 
                        path="/login" 
                        component={ LoginScreen } 
                        isAuthenticated={ user.logged }
                    /> */}

                    {/* <PrivateRoute 
                        path="/" 
                        component={ DashboardRoutes } 
                        isAuthenticated={ user.logged }
                    /> */}
                </Switch>
            </div>
        </Router>
    )
}
