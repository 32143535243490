import React from "react";

import { Footer } from "../ui/Footer";
import { Contacto } from "../ui/Contacto";

export const ContactoScreen = () => {
  return (
      <>

    <Contacto/>
    <Footer/>
</>

    
  );
};
