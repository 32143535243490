import React from 'react'
//import React, { useReducer, useEffect } from 'react'
import { AppRouter } from './routers/AppRouter'
// import { AuthContext } from './auth/AuthContext'
// import { authReducer } from './auth/authReducer'

// const init = () => {
//     return JSON.parse(localStorage.getItem('user')) || { logged: false };
// }


export const PlanetWeb = () => {
    
    // const [ user, dispatch ] = useReducer(authReducer, {}, init);

    // useEffect(() => {
    //     localStorage.setItem( 'user', JSON.stringify(user) );
    // }, [user])
    
    // <AuthContext.Provider value={{ user, dispatch }}>
    // </AuthContext.Provider> 
    
    return (

            <AppRouter />

    )
}
