import React from "react";
import { Link } from "react-router-dom";

export const CardPlan = ({
  id,
  tipo,
  icon,
  icon_tipo,
  titulo,
  velocidad,
  descUno,
  descDos,
  precio,
  btnClass,
}) => {


  return (
    <>
      <div className="col-12 col-md-5 col-lg-3 my-4">
        <div className="card rounded-0 shadow animate__animated animate__fadeInUp animate__fast">
          <div className="d-flex justify-content-between mx-2">
          <img
            src={`../assets/footageplanet/${icon}.png`}
            style={{ width: "20%", height: "20%"}}
            className="mt-3 ml-3"
            alt="Logo fibra optica"
            />
             <img
            src={`../assets/footageplanet/ico_${icon_tipo}.png`}
            style={{ width: "20%", height: "20%" }}
            className="mt-3 mr-3"
            alt="Logo fibra optica"
            />
            </div>
          <div className="card-body">
            <h4 className="card-title m-0 font-size-1">
              {tipo} ({id})
            </h4>
            <h4 className="card-title m-0 font-weight-bold">{titulo}</h4>
            <br />
            <p className="card-text m-0">24 hs. Online</p>
            <p className="card-text m-0">{descDos}</p>
          
          </div>
          <Link to={{pathname: "/contacto", state: `Quiero solicitar mi plan ${titulo} ${tipo}`  }}className={`btn-card btn-card-${btnClass}`} >
           CONSULTÁ
          </Link>
        </div>
      </div>

    </>
  );
};
