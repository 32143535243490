import React, { useState } from "react";

export const Accordion = ({ zona, tipo, icon, content, open }) => {
  // const [isActive, setIsActive] = useState(false);
  const [isOpen, setOpen] = useState(open);
  

  const mapeoContent = content.map((c) => (
    <li key={`${c}${tipo}${zona}`} className="zonas-list mb-1">
      . {c}
    </li>
  ));

  return (
    <div className="accordion-zona-wrapper mt-6">
      <div className={`accordion-zona-title pl-1`} onClick={()=> setOpen(!isOpen)}>
        {isOpen ? <img style={{ width: "5%" }} src={`../assets/footageplanet/rightarrow.ico`} alt="Flecha derecha"/> : <img style={{ width: "5%" }} src={`../assets/footageplanet/downarrow.ico`} alt="Flecha abajo"/>}
        <div className=" ml-4 mr-auto">
        <div className="font-weight-bold text-dark">{zona}</div>
        <div className="font-weight-light">{tipo}</div>
      </div>
      <img
              src={`../assets/footageplanet/${icon}.png`}
              style={{ width: "10%" }}
              className="mr-1"
              alt="Logo fibra optica"
            />
      </div>
      <div className={`accordion-zona-item ${!isOpen ? "collapsed" : ""}`}>
        <div className="accordion-zona-content">
          <ul className="list-group ml-4">{mapeoContent}</ul>
        </div>
      </div>
    </div>
  );
};
