import React from "react";
import { Link } from "react-router-dom";

export const ZonaCobertura = () => {
  return (
<section id="zona-cobertura"   className="mt-8" >
    <div  className="row m-0" style={{minHeight: "400px"}} >
        <div className=".d-none .d-md-block col-md-6 p-0 zonas-a" >
        </div>
        <div className="col-12 col-md-6  p-0 zonas-b">
            <div className="d-flex  flex-column margin" >
        <img  className="img-fluid mb-3" src={process.env.PUBLIC_URL + '/assets/footageplanet/zonas_B_text.png'} alt="zonas de cobertura" />
        <Link to="/zonasdecobertura" className="btn btn-outline-light rounded-pill px-2 py-1" >VER MAPA</Link>
        
            </div>

        </div>
    </div>

</section>


    
  );
};
