import React from "react";
import {Carousel} from "react-bootstrap"
// import img1 from "/public/assets/footageplanet/bh_fibraoptica_img.jpg"
import { Link } from "react-router-dom";

export const Hero = () => {
  return (

  <Carousel indicators={false} className="mt-75-m">
  <Carousel.Item  className="hero ">
    <img
      className="d-block hero-img"
      src={process.env.PUBLIC_URL + '/assets/footageplanet/bh_fibraoptica_img.jpg'}
      alt="First slide"
    />
    <Carousel.Caption>
    <div className="hero__text mb-7 font-bold">
      <img className="mb-2" src={`../assets/footageplanet/icon_fibraoptica_BLANCO.png`} alt="Logo fibra blanco" />
    <h1 className="m-0 font-weight-bold ">FIBRA ÓPTICA</h1>
    <p className="mt-3 mb-4 font-weight-bold">LA TECNOLOGÍA QUE LLEGÓ PARA QUEDARSE</p>
    <Link to={{pathname: "/planes", state: {tipoH: "fibra", classFH: "black", classIH: "secondary"}}} className="font-weight-bold btn btn-outline-light rounded-pill  px-4 py-1 mt-4" >¡ QUIERO TENERLA !</Link>
    
  </div>
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item className="hero">
    <img
      className="d-block hero-img"
      src={process.env.PUBLIC_URL + '/assets/footageplanet/bh_inalambrico_img.jpg'}
      alt="Second slide"
    />

    <Carousel.Caption>
    <div className="hero__text mb-7 font-bold">
      <img  className="mb-2" src={`../assets/footageplanet/icon_inalambrico_BLANCO.png`} alt="Logo fibra blanco" />
    <h1 className="m-0 font-weight-bold ">INALÁMBRICO</h1>
    <p className="mt-3 mb-4 font-weight-bold">LA SOLUCIÓN PARA ESTAR CONECTADO</p>
    <Link to={{pathname: "/planes", state: {tipoH: "inalambrico", classFH: "secondary", classIH: "black"}}} className="font-weight-bold btn btn-outline-light rounded-pill  px-4 py-1 mt-4" >¡ QUIERO TENERLA !</Link>
    
  </div>
    </Carousel.Caption>
  </Carousel.Item >
  <Carousel.Item className="hero">
    <img
      className="d-block hero-img"
      src={process.env.PUBLIC_URL + '/assets/footageplanet/bh_rural_img.jpg'}
      alt="Third slide"
    />

   <Carousel.Caption>
    <div className="hero__text mb-7 font-bold">
      <img className="mb-2" src={`../assets/footageplanet/icon_inalambrico_BLANCO.png`} alt="Logo fibra blanco" />
    <h1 className="m-0 font-weight-bold ">CONECTIVIDAD RURAL</h1>
    <p className="mt-3 mb-4 font-weight-bold">LLEGAMOS DÓNDE VOS NOS NECESITES</p>
    <Link to={{pathname: "/planes", state: {tipoH: "inalambrico", classFH: "secondary", classIH: "black"}}}  className="font-weight-bold btn btn-outline-light rounded-pill  px-4 py-1 mt-4" >¡ QUIERO TENERLA !</Link>
    

  </div>
    </Carousel.Caption>
  </Carousel.Item>
</Carousel>

  );
};
