const consumoUno = {
  velocidad: "250 Kbps",
  img: "consumo_01_radios",
  calidad: "Óptima",
};
const consumoDos = {
  velocidad: "350 Kbps",
  img: "consumo_02_spotify",
  calidad: "Óptima",
};

const consumoTres = {
  velocidad: "500 Kbps",
  img: "consumo_03_netflix",
  calidad: "Baja",
};

const consumoCuatro = {
  velocidad: "1 Mbps",
  img: "consumo_04_youtube480",
  calidad: "Baja",
};

const consumoCinco = {
  velocidad: "1,5 Mbps",
  img: "consumo_03_netflix",
  calidad: "Media",
};
const consumoSeis = {
  velocidad: "3 Mbps",
  img: "consumo_05_youtube720",
  calidad: "Media",
};

const consumoSiete = {
  velocidad: "5 Mbps",
  img: "consumo_06_netflix_youtube",
  calidad: "HD",
};

const consumoOcho = {
  velocidad: "7 Mbps",
  img: "consumo_07_zoom",
  calidad: "Media",
};

const consumoNueve = {
  velocidad: "25 Mbps",
  img: "consumo_08_netflix4k",
  calidad: "Ultra HD",
};

export const consumosInfo = [
  consumoUno,
  consumoDos,
  consumoTres,
  consumoCuatro,
  consumoCinco,
  consumoSeis,
  consumoSiete,
  consumoOcho,
  consumoNueve,
];
