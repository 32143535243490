import React, { useState } from "react";
import {ConsumoRec} from "./ConsumoRec"
import { CarouselOpt } from "./CarouselOpt.js";
import { slidesOptim } from "./SlidesOpt";
import { slidesPagos } from "./SlidesPagos";
import { useLocation } from "react-router-dom";
import { CarouselPagos } from "./CarouselPagos";


export const RecomendacionesScreen = () => {
  const mensajeRecom = useLocation() 
  console.log(mensajeRecom);

  const [isActive, setIsActive] = useState(
    mensajeRecom.state ? mensajeRecom.state :{
    optimizar: true,
    consumo: false,
    facturacion: false 
  })


  let setOptimizar = () => {
    setIsActive({
    optimizar: true,
    consumo: false,
    facturacion: false 
    })
  }
  console.log(isActive)

  let setConsumo = () => {
     setIsActive({
      optimizar: false,
      consumo: true,
      facturacion: false 
      })
  }
  let setFacturacion= () => {
      setIsActive({
      optimizar: false,
      consumo: false,
      facturacion: true
    })
 }
 let mainScreen = ()=>{
   if(isActive.optimizar){
   return <CarouselOpt slidesOptim={slidesOptim} />
 } else if(isActive.consumo){
  return <ConsumoRec/>
 } else if(isActive.facturacion){
  return <CarouselPagos slidesPago={slidesPagos} />
 } 
 }
  return (
    <>
      <section id="recomendaciones" className="recomendaciones" >
        <div className="row " >
          <div className="col-12 col-md-4 recom-slide">
            <div className="mt-5-5">
              <h5 className="ml-5-5 font-size-15 font-weight-bold font-montserrat">
                RECOMENDACIONES
              </h5>
              <hr className="w-50 ml-5-5 mt-1 mb-3" />
              <div className="pl-md-4  ml-md-5 mt-2">
              <button
                onClick={setOptimizar}
                className="btn d-flex flex-row recomendacionesBoton align-items-center mb-2 animate__animated animate__slideInLeft animate__faster"
                >
                {isActive.optimizar ? 
                <img
                src={`../assets/footageplanet/rightarrow_121279.svg`}
                alt="Planet logo"
                className="img-fluid"
                style={{ height: "20px" }}
                />
                  : <img
                  src={`../assets/footageplanet/flecha.png`}
                  alt="Planet logo"
                  className="img-fluid"
                  style={{ height: "20px" }}
                  /> }
                <p className={`font-weight-light font-size-18  pl-2 seleccionado ${isActive.optimizar ? "font-weight-bold" : ""} `}>
                  Optimizar la conexión
                </p>
              </button>
              <button
                onClick={setConsumo}
                className="btn d-flex flex-row recomendacionesBoton align-items-center mb-2 animate__animated animate__slideInLeft animate__fast"
                >
                 {isActive.consumo ? 
                <img
                src={`../assets/footageplanet/rightarrow_121279.svg`}
                alt="Planet logo"
                className="img-fluid"
                style={{ height: "20px" }}
                />
                  : <img
                  src={`../assets/footageplanet/flecha.png`}
                  alt="Planet logo"
                  className="img-fluid"
                  style={{ height: "20px" }}
                  /> }
                <p className={`font-weight-light font-size-18  pl-2 seleccionado ${isActive.consumo ? "font-weight-bold" : ""} `}>
                  Consumo de ancho de banda
                </p>
              </button>
              <button
                onClick={setFacturacion}
                className="btn d-flex flex-row recomendacionesBoton align-items-center mb-2 animate__animated animate__slideInLeft "
                >
                {isActive.facturacion ? 
                <img
                src={`../assets/footageplanet/rightarrow_121279.svg`}
                alt="Planet logo"
                className="img-fluid"
                style={{ height: "20px" }}
                />
                  : <img
                  src={`../assets/footageplanet/flecha.png`}
                  alt="Planet logo"
                  className="img-fluid"
                  style={{ height: "20px" }}
                  /> }
                <p className={`font-weight-light font-size-18  pl-2 seleccionado ${isActive.facturacion ? "font-weight-bold" : ""} `}>
                  Cómo pagar mi factura
                </p>
              </button>
            
                  </div>
            </div>
          </div>
          <div className="col-12 col-md-8 recom-info animate__animated animate__fadeIn">
           
        {mainScreen()}

        
          </div>
        </div>
      </section>
    </>
  );
};
