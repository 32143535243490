import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { IconContext } from "react-icons/lib";

export const CarouselPagos = ({ slidesPago }) => {
  const [currentP, setCurrentP] = useState(0);
  const length = slidesPago.length;
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const nextSlide = () => {
    setCurrentP(currentP === length - 1 ? 0 : currentP + 1);
  };

  const prevSlide = () => {
    setCurrentP(currentP === 0 ? length - 1 : currentP - 1);
  };

  const numSlides = (num) => {
    setCurrentP(num);
  };
  if (!Array.isArray(slidesPago) || slidesPago.length <= 0) {
    return null;
  }
  let liList = [];

  for (let i = 0; i < length; i++) {
    liList.push(
      <li
        key={(i + 10).toString()}
        onClick={() => numSlides(i)}
        className={
          i === currentP ? "mx-2 pointer activeNum" : "mx-2 mt-1 pointer"
        }
      >
        0{i + 1}
      </li>
    );
  }

  // HASTA ACA CTRL Z
  return (
    <>
      <div className="pagos pt-2">
        {slidesPago.map((slideP, index) => {
          return (
            <>
              <div
                className={
                  index === currentP ? "slideCarousel active" : "slideCarousel"
                }
                key={index + slideP}
              >
                {index === currentP ? (
                  <div className="pagos__text font-bold mb-2 mt-md-5">
                    <h1 className="m-0 font-weight-bold ">{slideP.titulo}</h1>
                    <img
                      className="my-3"
                      src={`../assets/footageplanet/${slideP.img}.jpg`}
                      alt="Metodo de pago 1"
                    />
                    <p className="mt-3 w-100 ">
                      {slideP.desc}
                      <br />
                      {slideP.titulo === "PAGO VIRTUAL" ||
                      slideP.titulo === "TRANSFERENCIA" ? (
                        <React.Fragment>
                          <span
                            className="font-weight-bold btn-modal mt-2"
                            onClick={handleShow}
                          >
                            {slideP.desc2}
                          </span>

                          <Modal
                            size="lg"
                            show={show}
                            onHide={handleClose}
                            className="my-5"
                          >
                            {slideP.titulo === "PAGO VIRTUAL" ? (
                              <React.Fragment>
                                <Modal.Header closeButton>
                                  <Modal.Title>Ejemplo</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                  <img
                                    className="my-4"
                                    src={
                                      "../assets/footageplanet/pago_1_ejemplo.jpg"
                                    }
                                    alt="Consejo de optimizacion 1"
                                  />
                                </Modal.Body>
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                <Modal.Header closeButton className="mt-4">
                                  <Modal.Title>
                                    Datos para transferencia
                                  </Modal.Title>
                                </Modal.Header>

                                <Modal.Body className="my-4">
                                  <div>
                                    <div className="text-center mb-4">

                                    <p>
                                      {" "}
                                      <span className="font-weight-bold">
                                        Titular:{" "}
                                      </span>
                                      SCDPLANET S.A{" "}
                                    </p>
                                    <p>
                                      {" "}
                                      <span className="font-weight-bold">
                                        CUIT:{" "}
                                      </span>
                                      30-70746945-1{" "}
                                    </p>
                                    </div>
                                    <div className="d-flex">
                                      <div className="mr-md-3">
                                        <p>
                                          {" "}
                                          <span className="font-weight-bold">
                                            Banco:{" "}
                                          </span>
                                          Santander Río{" "}
                                        </p>
                                        <p>
                                          {" "}
                                          <span className="font-weight-bold">
                                            Cuenta Corriente $:{" "}
                                          </span>
                                          488-007108/6{" "}
                                        </p>
                                        <p>
                                          {" "}
                                          <span className="font-weight-bold">
                                            CBU:{" "}
                                          </span>
                                          0720488720000000710868{" "}
                                        </p>
                                        <p>
                                          {" "}
                                          <span className="font-weight-bold">
                                            Alias: SCD.PLANET.SA
                                          </span>{" "}
                                        </p>
                                      </div>
                                      <div className="ml-md-3">
                                        <p>
                                          {" "}
                                          <span className="font-weight-bold">
                                            Banco:{" "}
                                          </span>
                                          Provincia{" "}
                                        </p>
                                        <p>
                                          {" "}
                                          <span className="font-weight-bold">
                                            Cuenta Corriente $:{" "}
                                          </span>
                                          7031-5438/0{" "}
                                        </p>
                                        <p>
                                          {" "}
                                          <span className="font-weight-bold">
                                            CBU:{" "}
                                          </span>
                                          0140092201703100543804{" "}
                                        </p>
                                        <p>
                                          {" "}
                                          <span className="font-weight-bold">
                                            Alias: SCD.PLANET
                                          </span>{" "}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </Modal.Body>
                              </React.Fragment>
                            )}
                          </Modal>
                        </React.Fragment>
                      ) : (
                        <span className="font-weight-bold  ">
                          {slideP.desc2}
                        </span>
                      )}
                    </p>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </>
          );
        })}
      </div>

      <div className="d-flex justify-content-center mt-5">
        <IconContext.Provider
          value={{
            style: {
              color: "#191919",
              opacity: "0.2",
              height: "25px",
              verticalAlign: "middle",
              fontSize: "36px",
            },
          }}
        >
          <IoIosArrowBack className="mx-2 pointer mt-1 " onClick={prevSlide} />

          <ul className="d-flex mx-2 ">{liList}</ul>
          <IoIosArrowForward
            className="mx-2 pointer mt-1 "
            onClick={nextSlide}
          />
        </IconContext.Provider>
      </div>
    </>
  );
};
