import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { HomeScreen } from '../components/home/HomeScreen';
import { PlanesScreen } from '../components/planes/PlanesScreen';
import { ContactoScreen } from '../components/contacto/ContactoScreen';
import { ZonasScreen } from '../components/zonaCobertura/ZonasScreen';
import { RecomendacionesScreen } from '../components/recomendaciones/RecomendacionesScreen';
import { NosotrosScreen } from '../components/nosotros/NosotrosScreen';
import { NavBarPlanet } from '../components/ui/NavBarPlanet';


export const DashboardRoutes = () => {


    return (
        <>
            <NavBarPlanet/>
    <a href="https://wa.me/message/RCW6FBD2UV2LC1" className="floatWpp" target="_blank" rel="noopener noreferrer">
    <i className="fa fa-whatsapp my-floatWpp"></i>
    </a>
            {/* <div className="container"> */}
                
                <Switch>
                
                <Route exact path="/" component={ HomeScreen } />
                <Route exact path="/planes" component={ PlanesScreen } />
                <Route exact path="/zonasdecobertura" component={ ZonasScreen } />
                <Route exact path="/recomendaciones" component={ RecomendacionesScreen } />
                <Route exact path="/nosotros" component={ NosotrosScreen } />
                <Route exact path="/contacto" component={ ContactoScreen } />

                    <Redirect to="/" />
                </Switch>
            {/* </div> */}


        </>
    )
}
