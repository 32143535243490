import React from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { Contacto } from "../ui/Contacto";
import { Footer } from "../ui/Footer";
import { Recomendaciones } from "../ui/Recomendaciones";
import { ZonaCobertura } from "../ui/ZonaCobertura";
import { CardList } from "./CardList";

export const PlanesScreen = () => {

  const mensajeHero = useLocation() 
  console.log(mensajeHero);
  


  const [tipo, setTipo] = useState(mensajeHero.state  ? mensajeHero.state.tipoH : "fibra" );
  const [classF, setClassF] = useState(mensajeHero.state ? mensajeHero.state.classFH : "black");
  const [classI, setClassI] = useState(mensajeHero.state ? mensajeHero.state.classIH : "secondary");
 
  let changeButton = (tipoBoton, colorF, colorI) => {
    setTipo(tipoBoton);
    setClassF(colorF)
    setClassI(colorI)

  };

  
  return (
    <>
      <div className=" d-flex flex-column">
        <div className="mx-5 mt-4 mb-3 d-flex justify-content-center row animate__animated animate__slideInDown">
          <div className="m-1 m-md-5 col-12 col-md-4">
            <img
              src={`../assets/footageplanet/icon_fibra_${classF}.png`}
              style={{ width: "15%" }}
              className="mr-4"
              alt="Logo fibra optica"
            />

            <button className={`btn-planes font-weight-bold text-${classF}`} onClick={() => changeButton("fibra", "black", "secondary")}>
              FIBRA ÓPTICA
            </button>
          </div>
          <div className="m-1 m-md-5 col-12 col-md-4">
            <img
              src={`../assets/footageplanet/icon_inalambrico_${classI}.png`}
              style={{ width: "15%" }}
              className="mr-4"
              alt="Logo inalambrico"
            />
            <button className={`btn-planes font-weight-bold text-${classI}`} onClick={() => changeButton("inalambrico", "secondary", "black")}>
              INALÁMBRICO
            </button>
          </div>
        </div>
       
        <CardList tipo={tipo} />
        <p className="text-center py-3 font-size-15">*Costos de instalación a cargo del cliente. Precios sujetos a zonas y servicio elegido</p>
      </div>
      <ZonaCobertura />
      <Recomendaciones />
      <Contacto />
      <Footer />
    </>
  );
};
