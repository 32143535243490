import React, { useState } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { IconContext } from "react-icons/lib";



export const CarouselOpt = ({slidesOptim}) => {
    
    const [current, setCurrent] = useState(0);
    const length = slidesOptim.length;
    
    const nextSlide = () => {
        setCurrent(current === length - 1 ? 0 : current + 1);

      };
    
      const prevSlide = () => {
        setCurrent(current === 0 ? length - 1 : current - 1);
      };
    
      const numSlides = (num) => {
        console.log(num);
        setCurrent(num);
      }
      if (!Array.isArray(slidesOptim) || slidesOptim.length <= 0) {
        return null;
      }
      let liList = [];

      for (let i = 0; i < length; i++) {
        liList.push(<li  key={i} onClick={()=>numSlides(i)} className= {i === current ? 'mx-2 pointer activeNum' : 'mx-2 mt-1 pointer'}>0{i+1}</li>);
      }

// HASTA ACA CTRL Z

  return (
    <>
      <div className="optim pt-2 pt-md-5">
       
      {slidesOptim.map((slide, index) => {
        return (
          <div
            className={index === current ? 'slideCarousel active' : 'slideCarousel'}
            key={index}
          >
            {index === current &&  slide         }
          </div>
        );
      })}
        </div>
      
      <div className="d-flex justify-content-center mt-5">

      <IconContext.Provider value={{  style: { color: "#191919", opacity:"0.2", height: "25px", verticalAlign: 'middle', fontSize: "36px" } }}>
          <IoIosArrowBack className="mx-2 pointer mt-1 " onClick={prevSlide}/>

          <ul className="d-flex mx-2 ">
         {liList}
          </ul>
          <IoIosArrowForward className="mx-2 pointer mt-1 " onClick={nextSlide} />

      </IconContext.Provider>
      </div>
    </>
  );
};
